import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "@tanstack/react-query";
import ReactPlayer from "react-player";

import { Carousel } from "@/components/Carousel";
import { getClass } from "@/services/class";
import { configs } from "@/configs";

const QUERY_KEY = "class-detail-dialog";

type ClassDetailDialogProps = {
  open: boolean;
  id?: string;
  onClose: () => void;
};

export function ClassDetailDialog({
  open: isOpen,
  id,
  onClose,
}: ClassDetailDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { data, isLoading } = useQuery(
    [QUERY_KEY, id],
    () => getClass(id ?? ""),
    { enabled: !!id }
  );

  if (!id && !data) return <></>;

  const imageUrls = data?.thumbnailUrls ?? [];

  const carousel = {
    imageUrls: imageUrls.length ? imageUrls : [configs.defaultImageURL],
    imgSx: { borderTopLeftRadius: "24px", borderTopRightRadius: "24px" },
  };

  const category =
    data?.categories?.map((category) => category.name).join(",") ?? "";

  const playerHeight = isMobile ? 174 : 292;
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={false}
      PaperProps={{
        sx: {
          maxWidth: "600px",
          width: { xs: "100%", md: "600px" },
          pb: { xs: 5, md: 6 },
          mx: 3,
          borderRadius: "24px",
        },
      }}
    >
      {onClose ? <DialogCloseButton onClose={onClose} /> : null}
      <DialogContent sx={{ p: "0 !important" }}>
        {!isLoading ? (
          <>
            <Carousel {...carousel} />
            <Stack gap={2} sx={{ px: { xs: 2, md: 5 } }}>
              <Typography
                variant="h6"
                alignSelf="center"
                mt={2}
                sx={{ wordBreak: "break-word" }}
              >
                {data?.name ?? ""}
              </Typography>
              <Stack gap={0.5}>
                <Typography variant="subtitle1" color="text.secondary">
                  ประเภทกิจกรรม
                </Typography>
                <Typography variant="body2">{category}</Typography>
              </Stack>
              {data?.youtubeUrl && (
                <Stack gap={0.5}>
                  <Typography variant="subtitle1" color="text.secondary">
                    Video
                  </Typography>
                  <ReactPlayer
                    url={data.youtubeUrl}
                    controls={true}
                    width="100%"
                    height={playerHeight}
                  />
                </Stack>
              )}
              {data?.description && (
                <Stack gap={0.5}>
                  <Typography variant="subtitle1" color="text.secondary">
                    รายละเอียด
                  </Typography>
                  <Typography variant="body2">
                    {data?.description ?? ""}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </>
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={400}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export function DialogCloseButton({ onClose }: { onClose: () => void }) {
  return (
    <IconButton
      onClick={onClose}
      sx={{
        zIndex: 10,
        position: "absolute",
        right: 0,
        top: 0,
        mr: { xs: 3.5, md: 4.5 },
        mt: { xs: 3.5, md: 4.5 },
        p: 0,
        color: "white",
        bgcolor: `rgba(0, 0, 0, 0.5)`,
        width: "35px",
        height: "35px",
        ":hover": {
          bgcolor: "black",
          opacity: 0.7,
        },
      }}
    >
      <CloseIcon />
    </IconButton>
  );
}
