import { useEffect, useState } from "react";
import * as yup from "yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { upload } from "@/lib/firebase/storage";
import { useMounted } from "@/utils";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { isFirebaseError } from "@/lib/firebase/auth";
import { now } from "@/lib/dateTime";

import { FileDropZone } from "./FileDropZone";

import type { InferType } from "yup";
import type { UploadModule } from "@/models";

export type FileForm = InferType<typeof schema>;

type ImageUploaderProps = {
  value: FileForm;
  onChange: (value: FileForm) => void;
  module: UploadModule;
};

export function ImageUploader({ value, onChange, module }: ImageUploaderProps) {
  const {
    fitnessCenter: { id: fitnessCentersId },
  } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const mounted = useMounted();

  useEffect(() => {
    if (value.url || !value.file) {
      return;
    }

    setIsLoading(true);

    const todayDate = now().toISODate();
    upload(
      `/fitnessCenter/${fitnessCentersId}/${module}/${todayDate}`,
      value.file
    )
      .then(
        (publicURL) =>
          mounted.current &&
          onChange({
            url: publicURL,
            file: value.file,
          } as FileForm)
      )
      .catch((error) => {
        console.error(error);
        if (isFirebaseError(error)) {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      })
      .finally(() => setIsLoading(false));
  }, [value, mounted, onChange, module, fitnessCentersId, enqueueSnackbar]);

  return (
    <Stack gap={2} alignItems="center">
      {value.url && (
        <Box
          component="img"
          src={value.url}
          alt="uploaded-file"
          width={{ xs: 160, md: 280 }}
          height={{ xs: 200, md: 352 }}
          borderRadius={1}
        />
      )}
      <FileDropZone
        inputId={module}
        onChange={(file) =>
          onChange({
            url: "",
            file: file,
          })
        }
        disabled={isLoading}
        hidden={!!value.url}
      />

      <Button
        component="label"
        variant="contained"
        color="primary"
        htmlFor={module}
        size="small"
        sx={{ width: "100px" }}
        disabled={isLoading}
      >
        เลือกรูป
      </Button>
      <Typography variant="body1">อัพโหลดภาพสลิปการโอนเงิน</Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        textAlign="center"
        fontSize="0.75rem"
      >
        (สูงสุด 1 ไฟล์ ขนาดไม่เกิน 2MB/ไฟล์ นามสกุล .JPG หรือ .PNG เท่านั้น)
      </Typography>
    </Stack>
  );
}

const schema = yup.object({
  url: yup.string().required(),
  file: yup.mixed<File>(),
});
